import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
import displayWarningToast from '../../utils/displayWarningToast';

type BaseQueryResult<T = unknown> = {
  data?: unknown | T;
  error?: FetchBaseQueryError;
  meta?: FetchBaseQueryMeta | undefined;
};

type RefreshData = {
  accessToken: string;
};

interface UserState {
  accessToken: string;
  refreshToken: string;
}

const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_BASE_API_URL as string,
  prepareHeaders: (headers, { getState }) => {
    const state = getState() as { user: UserState };
    const token = state.user.accessToken;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (
    result.error &&
    result.error.status === 403 &&
    (result.error?.data as any).message === 'Forbidden' &&
    (api.getState() as { user: UserState }).user.refreshToken
  ) {
    const refreshResult: BaseQueryResult<RefreshData> = await baseQuery(
      {
        url: '/auth/refresh',
        method: 'POST',
        body: {
          refreshToken: (api.getState() as { user: UserState }).user
            .refreshToken,
        },
      },
      api,
      extraOptions
    );
    if (refreshResult.data) {
      api.dispatch({
        type: 'user/updateAccessToken',
        payload: (refreshResult.data as RefreshData).accessToken,
      });
      result = await baseQuery(args, api, extraOptions);
    } else {
      displayWarningToast('Votre session a expiré, veuillez vous reconnecter');
      api.dispatch({
        type: 'user/logout',
      });
    }
  }
  return result;
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['User'],
  endpoints: (builder) => ({}),
});
